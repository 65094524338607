export default [
    // Product
    {
        path: '/manage/product/list',
        name: 'manage-product-list',
        component: () => import('@/views/manage/product/list/Products.vue'),
    },
    {
        path: '/manage/product/preview/:id',
        name: 'manage-product-preview',
        component: () => import('@/views/manage/product/preview/ProductPreview.vue'),
    },
    {
        path: '/manage/product/add/',
        name: 'manage-product-add',
        component: () => import('@/views/manage/product/add/ProductAdd.vue'),
    },
    {
        path: '/manage/product/edit/:id',
        name: 'manage-product-edit',
        component: () => import('@/views/manage/product/edit/ProductEdit.vue'),
    },
    // Category
    {
        path: '/manage/category/list',
        name: 'manage-category-list',
        component: () => import('@/views/manage/category/list/Category.vue'),
    },
    {
        path: '/manage/category/preview/:id',
        name: 'manage-category-preview',
        component: () => import('@/views/manage/category/preview/CategoryPreview.vue'),
    },
    {
        path: '/manage/category/add/',
        name: 'manage-category-add',
        component: () => import('@/views/manage/category/add/CategoryAdd.vue'),
    },
    {
        path: '/manage/category/edit/:id',
        name: 'manage-category-edit',
        component: () => import('@/views/manage/category/edit/CategoryEdit.vue'),
    },
    // NKSX
    {
        path: '/manage/nksx/list',
        name: 'manage-nksx-list',
        component: () => import('@/views/manage/nksx/list/Nksx.vue'),
    },
    // {
    //     path: '/manage/category/preview/:id',
    //     name: 'manage-category-preview',
    //     component: () => import('@/views/manage/category/preview/CategoryPreview.vue'),
    // },
    // {
    //     path: '/manage/nksx/add/',
    //     name: 'manage-nksx-add',
    //     component: () => import('@/views/manage/nksx/add/NksxAdd.vue'),
    // },
    {
        path: '/manage/nksx/edit/:id',
        name: 'manage-nksx-edit',
        component: () => import('@/views/manage/nksx/edit/NksxEdit.vue'),
    },
    // Tin tức
    {
        path: '/manage/post/list',
        name: 'manage-post-list',
        component: () => import('@/views/manage/post/list/Post.vue'),
    },
    {
        path: '/manage/post/add/',
        name: 'manage-post-add',
        component: () => import('@/views/manage/post/add/PostAdd.vue'),
    },
    {
        path: '/manage/post/edit/:id',
        name: 'manage-post-edit',
        component: () => import('@/views/manage/post/edit/PostEdit.vue'),
    },
    // Nghiên cứu khoa học
    {
        path: '/manage/nghien-cuu-khoa-hoc/list',
        name: 'manage-nckh-list',
        component: () => import('@/views/manage/nckh/list/Nckh.vue'),
    },
    {
        path: '/manage/nghien-cuu-khoa-hoc/add/',
        name: 'manage-nckh-add',
        component: () => import('@/views/manage/nckh/add/NckhAdd.vue'),
    },
    {
        path: '/manage/nghien-cuu-khoa-hoc/edit/:id',
        name: 'manage-nckh-edit',
        component: () => import('@/views/manage/nckh/edit/NckhEdit.vue'),
    },
    // Hướng dẫn kỹ thuật
    {
        path: '/manage/huong-dan-ky-thuat/list',
        name: 'manage-hdkt-list',
        component: () => import('@/views/manage/hdkt/list/Hdkt.vue'),
    },
    {
        path: '/manage/huong-dan-ky-thuat/add/',
        name: 'manage-hdkt-add',
        component: () => import('@/views/manage/hdkt/add/HdktAdd.vue'),
    },
    {
        path: '/manage/huong-dan-ky-thuat/edit/:id',
        name: 'manage-hdkt-edit',
        component: () => import('@/views/manage/hdkt/edit/HdktEdit.vue'),
    },
    // Hỗ trợ
    {
        path: '/manage/support/list',
        name: 'manage-support-list',
        component: () => import('@/views/manage/support/list/Support.vue'),
    },
    // Banner
    {
        path: '/manage/banner/list',
        name: 'manage-banner-list',
        component: () => import('@/views/manage/banner/list/Banner.vue'),
    },
    {
        path: '/manage/banner/add',
        name: 'manage-banner-add',
        component: () => import('@/views/manage/banner/add/BannerAdd.vue'),
    },
    {
        path: '/manage/banner/edit/:id',
        name: 'manage-banner-edit',
        component: () => import('@/views/manage/banner/edit/BannerEdit.vue'),
    },
    // Upload
    {
        path: '/manage/file/upload',
        name: 'manage-upload-list',
        component: () => import('@/views/manage/upload/list/HrdcFileUpload.vue'),
    },

    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/error/Error404.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
        },
    },
    {
        path: '/login',
        name: 'auth-login',
        component: () => import('@/views/pages/authentication/Login.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
]
